import { useEffect, useState } from "react";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20 22H18V20C18 19.2044 17.6839 18.4413 17.1213 17.8787C16.5587 17.3161 15.7956 17 15 17H9C8.20435 17 7.44129 17.3161 6.87868 17.8787C6.31607 18.4413 6 19.2044 6 20V22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13ZM12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11Z"
      fill="#9FA0A0"
    />
  </svg>
);
const ChildrenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5 20H19V22H5V20ZM12 18C9.87827 18 7.84344 17.1571 6.34315 15.6569C4.84285 14.1566 4 12.1217 4 10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10C20 12.1217 19.1571 14.1566 17.6569 15.6569C16.1566 17.1571 14.1217 18 12 18ZM12 16C13.5913 16 15.1174 15.3679 16.2426 14.2426C17.3679 13.1174 18 11.5913 18 10C18 8.4087 17.3679 6.88258 16.2426 5.75736C15.1174 4.63214 13.5913 4 12 4C10.4087 4 8.88258 4.63214 7.75736 5.75736C6.63214 6.88258 6 8.4087 6 10C6 11.5913 6.63214 13.1174 7.75736 14.2426C8.88258 15.3679 10.4087 16 12 16Z"
      fill="#9FA0A0"
    />
  </svg>
);

export default function Users({
  addChild,
  setAddChild,
  addPerson,
  setAddPerson,
  adult_price,
  child_price,
  setNumPerson,
  setCustomChildValue,
  packageDetails_id,
  summer_camp,
  packageTickets,
  setPackageTickets,
  groupTickets,
  setGroupTickets,
}) {
  // const [num_adult, setNumAdult] = useState(addPerson);
  // const [num_child, setNumChild] = useState(addChild);
  // const [adultLimitMax, setAdultLimitMax] = useState(99);
  // const [adultLimitMin, setAdultLimitMin] = useState(0);
  // const [childLimitMax, setChildLimitMax] = useState(99);
  // const [childLimitMin, setChildLimitMin] = useState(0);
  // const [childValues, setChildValues] = useState([]);
  // const [child, setChild] = useState([]);
  const locale = useStorage();

  // useEffect(() => {
  //   if (packageDetails_id === "2501") {
  //     setChildValues([
  //       { label: "All Days", price: 290, checked: true },
  //       { label: "18 Aug: Videos Lab", price: 80, checked: false },
  //       { label: "19 Aug: Photos Lab", price: 80, checked: false },
  //       { label: "20 Aug: Art Lab", price: 80, checked: false },
  //       {
  //         label: "21 Aug: Animations Lab",
  //         price: 80,
  //         checked: false,
  //       },
  //     ]);
  //   } else if (packageDetails_id === "2502") {
  //     setChildValues([
  //       { label: "All Days", price: 744, checked: true },
  //       {
  //         label: "25 Aug: Emotional Release",
  //         price: 276,
  //         checked: false,
  //       },
  //       {
  //         label: "26 Aug: Conscious Relationships",
  //         price: 252,
  //         checked: false,
  //       },
  //       {
  //         label: "27 Aug: Word to Art-Painting Therapy",
  //         price: 252,
  //         checked: false,
  //       },
  //     ]);
  //   } else if (packageDetails_id === "2503") {
  //     setChildValues([
  //       { label: "All Days", price: 720, checked: true },
  //       {
  //         label: "4 Aug: Resin Art with Flowers Workshop",
  //         price: 185,
  //         checked: false,
  //       },
  //       {
  //         label: "5 Aug: Jesmonite Terrazzo Workshop",
  //         price: 185,
  //         checked: false,
  //       },
  //       {
  //         label: "6 Aug: Concrete Cement Art",
  //         price: 185,
  //         checked: false,
  //       },
  //       {
  //         label: "7 Aug: Boho-style Painting on Tote Bags",
  //         price: 185,
  //         checked: false,
  //       },
  //     ]);
  //   } else if (packageDetails_id === "2504") {
  //     setChildValues([
  //       { label: "All Days", price: 780, checked: true },
  //       {
  //         label: "11 Aug: Create a Viral Tiktok/Reel Video",
  //         price: 205,
  //         checked: false,
  //       },
  //       {
  //         label: "12 Aug: Portrait Photography with Natural/Studio Lights",
  //         price: 205,
  //         checked: false,
  //       },
  //       {
  //         label: "13 Aug: Capture Beautiful Sunset Photos",
  //         price: 205,
  //         checked: false,
  //       },
  //       {
  //         label: "14 Aug: E-commerce/Products Photography",
  //         price: 205,
  //         checked: false,
  //       },
  //     ]);
  //   } else if (packageDetails_id === "2505") {
  //     setChildValues([
  //       { label: "All Days", price: 360, checked: true },
  //       {
  //         label: "22 Aug: Automation with Arduino Coding",
  //         price: 130,
  //         checked: false,
  //       },
  //       {
  //         label: "23 Aug: Automation with Arduino Coding",
  //         price: 130,
  //         checked: false,
  //       },
  //       {
  //         label: "24 Aug: Automation with Arduino Coding",
  //         price: 130,
  //         checked: false,
  //       },
  //     ]);
  //   } else if (packageDetails_id === "2596") {
  //     setChildValues([
  //       { label: "All Days", price: 780, checked: true },
  //       {
  //         label:
  //           "8 September 2022: Create a Viral TikTok/Reel Video - Per Session",
  //         price: 205,
  //         checked: false,
  //       },
  //       {
  //         label:
  //           "10 September 2022: Capture Beautiful Sunset Photos - Per Session",
  //         price: 205,
  //         checked: false,
  //       },
  //       {
  //         label:
  //           "11 September 2022: E- commerce/Products Photography - Per Session",
  //         price: 205,
  //         checked: false,
  //       },
  //     ]);
  //   } else if (packageDetails_id === "2595") {
  //     setChildValues([
  //       { label: "All Days", price: 290, checked: true },
  //       {
  //         label: "1-day (2 sessions) Package fees",
  //         price: 160,
  //         checked: false,
  //       },
  //       {
  //         label: "3 September 2022: Photos Lab - Fees per session",
  //         price: 80,
  //         checked: false,
  //       },
  //       {
  //         label: "3 September 2022: Videos Lab - Fees per session",
  //         price: 80,
  //         checked: false,
  //       },
  //       {
  //         label: "4 September 2022: Art Lab - Fees per session",
  //         price: 80,
  //         checked: false,
  //       },
  //       {
  //         label: "4 September 2022: Animations Lab - Fees per session",
  //         price: 80,
  //         checked: false,
  //       },
  //     ]);
  //   } else if (packageDetails_id === "2633") {
  //     setChildValues([
  //       { label: "Full Program", price: 450, checked: true },
  //       {
  //         label: "Day Pass (October 2)",
  //         price: 100,
  //         checked: false,
  //       },
  //       {
  //         label: "Day Pass (October 9)",
  //         price: 100,
  //         checked: false,
  //       },
  //       {
  //         label: "Day Pass (October 16)",
  //         price: 100,
  //         checked: false,
  //       },
  //       {
  //         label: "Day Pass (October 23)",
  //         price: 100,
  //         checked: false,
  //       },
  //       {
  //         label: "Day Pass (October 30)",
  //         price: 100,
  //         checked: false,
  //       },
  //     ]);
  //   } else if (packageDetails_id === "2634") {
  //     setChildValues([
  //       { label: "Full Program", price: 200, checked: true },
  //       {
  //         label: "Day Pass (October 3)",
  //         price: 75,
  //         checked: false,
  //       },
  //       {
  //         label: "Day Pass (October 4)",
  //         price: 75,
  //         checked: false,
  //       },
  //       {
  //         label: "Day Pass (October 5)",
  //         price: 75,
  //         checked: false,
  //       },
  //     ]);
  //   }
  // }, [packageDetails_id]);

  // useEffect(() => {
  //   const checkedValues = childValues.filter((ch) => ch.checked);
  //   setChild(checkedValues);
  // }, [childValues]);

  function minusPerson(title) {
    setPackageTickets((prev) => {
      const newTickets = prev.map((ticket) => {
        if (ticket.label === title) {
          // if (ticket.qty === Number(ticket.min_select)) {
          //   return ticket;
          // }
          const newQty = Math.max(0, ticket.qty - 1);
          return { ...ticket, qty: newQty };
        }
        return ticket;
      });
      return newTickets;
    });
    // if (title === "Adult") {
    //   if (num_adult === adultLimitMin) {
    //     return;
    //   }
    //   setNumPerson(num_adult - 1, num_child);
    //   setNumAdult(num_adult - 1);
    // } else {
    //   if (num_child === childLimitMin) {
    //     return;
    //   }
    //   setNumChild(num_child - 1);
    //   setNumPerson(num_adult, num_child - 1);
    // }
  }
    function plusPerson(title) {
    console.log('--- plusPerson Debug ---');
    console.log('Title:', title);
    
    setPackageTickets((prev) => {
      console.log('Previous tickets:', prev);
      
      // Calculate total selected tickets across all tickets
      const totalSelectedTickets = prev.reduce((sum, ticket) => sum + ticket.qty, 0);
      console.log('Total selected tickets:', totalSelectedTickets);

      const newTickets = prev.map((ticket) => {
        if (ticket.label === title) {
          console.log('Found matching ticket:', ticket);
          
          // Check if we should use group limit or individual limit
          if (ticket.group_overall_limit !== null && ticket.group_overall_limit !== undefined) {
            console.log('Using group limit:', ticket.group_overall_limit);
            // For group limit, check total tickets against group limit
            if (totalSelectedTickets >= ticket.group_overall_limit) {
              console.log('Cannot increment - group limit reached');
              return ticket;
            }
          } else {
            console.log('Using individual limit:', ticket.overall_limit);
            // For individual limit, check this ticket's quantity against its limit
            if (ticket.qty >= ticket.overall_limit) {
              console.log('Cannot increment - individual limit reached');
              return ticket;
            }
          }

          // If we get here, we can increment the ticket
          console.log('Incrementing ticket quantity from', ticket.qty, 'to', ticket.qty + 1);
          return { ...ticket, qty: ticket.qty + 1 };
        }
        return ticket;
      });
      
      console.log('New tickets state:', newTickets);
      return newTickets;
    });
  }

  const minusGroupPerson = (id, title) => {
    setGroupTickets((prev) => {
      const newTickets = prev.map((ticketGroup) => {
        if (ticketGroup.id === id) {
          const updatedTickets = ticketGroup.tickets.map((ticket) => {
            if (ticket.label === title) {
              if (ticket.qty === Number(ticket.min_select)) {
                return ticket;
              } else {
                return { ...ticket, qty: ticket.qty - 1 };
              }
            }
            return ticket;
          });
          return { ...ticketGroup, tickets: updatedTickets };
        }
        return ticketGroup;
      });
      return newTickets;
    });
  };

  const plusGroupPerson = (id, title) => {
    setGroupTickets((prev) => {
      const newTickets = prev.map((ticketGroup) => {
        if (ticketGroup.id === id) {
          const updatedTickets = ticketGroup.tickets.map((ticket) => {
            if (ticket.label === title) {
              if (
                ticket.qty ===
                (Number(ticket.overall_limit) < Number(ticket.max_select)
                  ? Number(ticket.overall_limit)
                  : Number(ticket.max_select))
              ) {
                return ticket;
              } else {
                return { ...ticket, qty: ticket.qty + 1 };
              }
            }
            return ticket;
          });
          return { ...ticketGroup, tickets: updatedTickets };
        }
        return ticketGroup;
      });
      return newTickets;
    });
  };

  // useEffect(() => {
  //   setNumAdult(addPerson);
  //   setNumChild(addChild);
  // }, [addPerson, addChild]);

  // const handleRadioChange = (value) => {
  //   if (value === "0") {
  //     childValues.map((ch, id) => {
  //       if (!id) {
  //         ch.checked = true;
  //       } else {
  //         ch.checked = false;
  //       }
  //     });
  //     setChildValues([...childValues]);
  //   } else {
  //     childValues.map((ch, id) => {
  //       if (!id) {
  //         ch.checked = false;
  //       } else if (id === Number(value)) {
  //         ch.checked = !ch.checked;
  //       }
  //     });
  //     setChildValues([...childValues]);
  //   }
  // };

  return (
    <div className="h-full flex flex-col justify-between mb-[45px] md:mb-0">
      <div></div>
      {!!packageTickets.length ? (
        <div>
          {/* Add the new tickets remaining display */}
          {packageTickets.some(ticket => {
            if (ticket.group_overall_limit !== null) {
              const remainingTickets = ticket.group_overall_limit - 
                packageTickets.reduce((sum, t) => sum + t.qty, 0);
              return ticket.group_overall_limit <= 5 || remainingTickets <= 5;
            }
            return false;
          }) && (
            <div className="text-white text-center mb-4" style={{
              background: "#171717",
              borderRadius: "5px",
              padding: "10px 0px"
            }}>
              {packageTickets[0].group_overall_limit - packageTickets.reduce((sum, ticket) => sum + ticket.qty, 0)} tickets remaining
            </div>
          )}
          
          {packageTickets.map((ticket, index) => (
            <div key={index}>
              {/* {summer_camp !== "1" && ( */}
              <h5 className="mb-[20px] text-white text-center text-[17px] font-semibold">
                {locale === "en" ? ticket.label : ticket.label_ar} - AED{" "}
                {ticket.price}
              </h5>
              {/* )} */}
              <div className="flex justify-center items-center w-full mb-[36px]">
                <button
                  className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
                  onClick={() => minusPerson(ticket.label)}
                >
                  -
                </button>
                <span className="min-w-[50px] mx-[15px] text-white text-[25px] text-center">
                  {ticket.qty}
                </span>
                <button
                  className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
                  onClick={() => plusPerson(ticket.label)}
                >
                  +
                </button>
              </div>
            </div>
          ))}
          {/* <hr className="border border-gray-300 my-4" />
        {groupTickets.map((ticketGroup) => (
          <div key={ticketGroup.id}>
            <div className="rounded-lg pt-12 p-4 mb-4">
              {ticketGroup.tickets.map((ticket, idx) => (
                <div key={idx}>
                  {summer_camp !== "1" && (
                    <h5 className="mb-[20px] text-white text-center text-[17px] font-semibold">
                      {ticket.label} - AED {ticket.price}
                    </h5>
                  )}
                  <div className="flex justify-center items-center w-full mb-[36px]">
                    <button
                      className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
                      onClick={() =>
                        minusGroupPerson(ticketGroup.id, ticket.label)
                      }
                    >
                      -
                    </button>
                    <span className="min-w-[50px] mx-[15px] text-white text-[25px] text-center">
                      {ticket.qty}
                    </span>
                    <button
                      className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
                      onClick={() =>
                        plusGroupPerson(ticketGroup.id, ticket.label)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))} */}
          {/* {adult_price && (
          <>
            {summer_camp !== "1" && (
              <h5 className="mb-[20px] text-white text-center text-[17px] font-semibold">
                {language.adult[locale]} - AED {adult_price}
              </h5>
            )}
            <div className="flex justify-center items-center w-full mb-[36px]">
              <button
                className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
                onClick={() => minusPerson("Adult")}
              >
                -
              </button>
              <span className="min-w-[50px] mx-[15px] text-white text-[25px] text-center">
                {num_adult}
              </span>
              <button
                className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
                onClick={() => plusPerson("Adult")}
              >
                +
              </button>
            </div>
          </>
        )}
        {child_price && (
          <>
            {summer_camp !== "1" && (
              <h5 className="mb-[20px] text-white text-center text-[17px] font-semibold">
                {language.children[locale]} - {child_price} AED
              </h5>
            )}
            <div className="flex justify-center items-center w-full mb-[36px]">
              <button
                className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
                onClick={() => minusPerson("Child")}
              >
                -
              </button>
              <span className="min-w-[50px] mx-[15px] text-white text-[25px] text-center">
                {num_child}
              </span>
              <button
                className="rounded-full border-[#C8C8C8] border-[1px] text-white w-[60px] h-[60px] text-[28px]"
                onClick={() => plusPerson("Child")}
              >
                +
              </button>
            </div>
          </>
        )} */}
        </div>
      ) : (
        <h5 className="text-white text-center text-[17px] font-semibold">
          No Tickets Available
        </h5>
      )}

      <div></div>

      {/* {(packageDetails_id === "2501" ||
        packageDetails_id === "2502" ||
        packageDetails_id === "2503" ||
        packageDetails_id === "2504" ||
        packageDetails_id === "2505" ||
        packageDetails_id === "2596" ||
        packageDetails_id === "2595" ||
        packageDetails_id === "2633" ||
        packageDetails_id === "2634") && (
        <>
          <Col sm={12} className="mb-4">
            <div className="render-input" style={{ display: "block" }}>
              {childValues.map((value, id) => {
                return (
                  <>
                    <div className="theme-radio">
                      <FormGroup check>
                        <Label check>
                          <Input
                            onChange={(e) => handleRadioChange(e.target.value)}
                            value={id}
                            type="checkbox"
                            checked={value.checked}
                          />
                          <span />
                          {value.label} {id === 0 && ":"} {id > 0 && "-"}{" "}
                          {value.price} {"AED"}
                        </Label>
                      </FormGroup>
                    </div>
                    {((packageDetails_id === "2595" && (!id || id === 1)) ||
                      ((packageDetails_id === "2596" ||
                        packageDetails_id === "2634" ||
                        packageDetails_id === "2633") &&
                        !id)) && <hr style={{ backgroundColor: "black" }}></hr>}
                  </>
                );
              })}
            </div>
          </Col>
        </>
      )} */}
    </div>
  );
}
